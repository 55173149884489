// This page is where we naivgate to get potential customers/ leads through ads.
import Navbar from '../../components/common/Header';
import { useNavigate, Link } from 'react-router-dom';
import { useState } from 'react';
import '../../styles/pages/landingPage.css';
import { set } from 'firebase/database';

const LandingPage = () => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const openPopUp = (e) => {
    e.preventDefault();
    setIsPopUpOpen(true);
  };

  const learnMoreInfo = (e) => {
    e.preventDefault();

    // SendEmail through email.js
  };

  return (
    <div className='landingPageContainer'>
      <svg
        id='sharetreesvg'
        xmlns='http://www.w3.org/2000/svg'
        width='162'
        height='42.189'
        viewBox='0 0 162 42.189'
      >
        <g
          id='Sharetree_Logo'
          data-name='Sharetree Logo'
          transform='translate(-216.16 -22.78)'
        >
          <path
            id='Path_1'
            data-name='Path 1'
            d='M228.707,22.78h0A12.547,12.547,0,0,0,216.16,35.327V49.073a12.547,12.547,0,0,0,12.547,12.547h0c.084,0,.166,0,.249-.006v-2.3c-.083,0-.166.007-.249.007h0c-5.65,0-10.231-4.952-10.231-11.062V36.139c0-6.109,4.581-11.062,10.231-11.062h0c5.65,0,10.231,4.953,10.231,11.062v12.12a10.95,10.95,0,0,1-7.631,10.7v2.388a12.549,12.549,0,0,0,9.946-12.275V35.326A12.546,12.546,0,0,0,228.707,22.78Z'
            fill='#00ae70'
          />
          <path
            id='Path_2'
            data-name='Path 2'
            d='M480.225,259.044h0a1.175,1.175,0,0,1-1.175-1.175V227.005a1.175,1.175,0,0,1,1.175-1.175h0a1.175,1.175,0,0,1,1.175,1.175v30.863A1.175,1.175,0,0,1,480.225,259.044Z'
            transform='translate(-251.27 -194.075)'
            fill='#135658'
          />
          <path
            id='Path_3'
            data-name='Path 3'
            d='M370.2,472.811h0a1.175,1.175,0,0,1-1.662,0l-4.617-4.617a1.175,1.175,0,0,1,0-1.662h0a1.175,1.175,0,0,1,1.662,0l4.617,4.617A1.176,1.176,0,0,1,370.2,472.811Z'
            transform='translate(-140.901 -423.808)'
            fill='#135658'
          />
          <path
            id='Path_4'
            data-name='Path 4'
            d='M490.4,315.466h0a1.175,1.175,0,0,1,0-1.662l4.521-4.521a1.175,1.175,0,0,1,1.662,0h0a1.175,1.175,0,0,1,0,1.662l-4.521,4.521A1.176,1.176,0,0,1,490.4,315.466Z'
            transform='translate(-261.79 -273.508)'
            fill='#135658'
          />
          <text
            id='Sharetree'
            transform='translate(251.16 53.875)'
            fill='#135658'
            font-size='26'
            font-family='Manrope'
            font-weight='700'
          >
            <tspan x='0' y='0'>
              Sharetree
            </tspan>
          </text>
        </g>
      </svg>
      <div className='headerContent'>
        <h1>
          DO YOU WANT TO
          <span className='headingColor'>GROW AND RETAIN MEMBERS</span>?
        </h1>
        <p>
          Getting and keeping members is hard. We make it easier for you and
          more appealing for them.
        </p>
        <h3>Get new members FREE by joining Sharetree.</h3>
      </div>
      <div className='contentHolder'>
        <div className='buttonContainer'>
          <div>
            <Link to='/signup'>
              <button type='submit' className='tryBtn'>
                Try Free
              </button>
            </Link>
          </div>
          <div>
            <button className='infoBtn' onClick={(e) => openPopUp(e)}>
              Learn More
            </button>
          </div>
        </div>

        <div className='videoContainer'>
          <iframe
            className='shadowVid'
            width='560'
            height='315'
            src='https://www.youtube.com/embed/LQakmDqQtpM?si=tHeme6GRDy2HG_6T'
            title='YouTube video player'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
          ></iframe>
        </div>
      </div>
      {isPopUpOpen && (
        <>
          <div
            className='popUpContainer'
            onClick={() => setIsPopUpOpen(false)}
          ></div>
          <div className='popUpformContainer'>
            <form className='landingPageForm'>
              <h1>Grow your business by helping your customers.</h1>
              <input
                type='text'
                name='fullName'
                placeholder='*Full Name'
              ></input>
              <input type='email' name='email' placeholder='*Email'></input>
              <input type='phone' name='phone' placeholder='*Phone'></input>
              <input
                type='text'
                name='company'
                placeholder='Company Name'
              ></input>
              <button
                className='learnMoreBtn'
                onClick={(e) => learnMoreInfo(e)}
              >
                Yes! I want to grow!
              </button>
              <p className='risk-free'>We'll send you more information.</p>
            </form>
          </div>
        </>
      )}
      <p className='mindbodyTag'>*Supports Mindbody Integration</p>
    </div>
  );
};

export default LandingPage;
