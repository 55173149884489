import '../styles/pages/Branches.css';
import React, { useState } from 'react';
import ReferralLinksTable from '../components/ReferralLinksTable/ReferralLinksTable';
import SideBar from '../components/sideBar/SideBar';
import BugForm from '../components/sideBar/BugForm';
import TopBar from '../components/topBar/topBar';

const ReferralLinks = () => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className='dashboardContainer'>
      <SideBar setIsVisible={setIsVisible} />

      <div className='componentContainer'>
        <BugForm isVisible={isVisible} setIsVisible={setIsVisible} />

        <div className='branchContainer'>
          <div className='componentContainers'>
            <TopBar />
            <div className='memberDetailContainer'>
              <ReferralLinksTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralLinks;
