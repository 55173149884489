import '../../styles/pages/Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faUsers } from '@fortawesome/free-solid-svg-icons';

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

const SideBar = ({ setIsVisible, selectedBtn = null }) => {
  const navigate = useNavigate();
  const [selected, isSelected] = useState('');
  //   Font Awesome Icons
  const homeIcon = <FontAwesomeIcon className='' icon={faHouse} />;
  const branchesIcon = <FontAwesomeIcon className='' icon={faUsers} />;

  // WHAT NEEDS TO HAPPEN WITH NAV BUTTONS ################################################
  const navigateButton = (selectedBtn) => {
    if (selectedBtn === 'dashboardBtn') {
      navigate('/dashboard/DashboardAnalytics');
    } else if (selectedBtn === 'branchesBtn') {
      navigate('/dashboard');
    } else if (selectedBtn === 'prospectiveMembersBtn') {
      navigate('/dashboard/prospective-members');
    } else if (selectedBtn === 'createReferralBtn') {
      navigate('/referral-links');
    } else if (selectedBtn === 'mindbodySetupBtn') {
      navigate('/mindbody-setup');
    }

    isSelected(selectedBtn);
  };

  // When clicking on button/
  // Selected button needs to be highlighted./
  // Color of font and icon change and Right side border hightlights green/
  // componentContainer will open the selected (branch or dashboard) modules providing new content /

  return (
    <div className='sideBarContainer'>
      <div className='sideBarContent'>
        <Link to={'/'}>
          <svg id='Sharetree_LogoDash'></svg>
        </Link>
        <div className='dashSelectionContainer'>
          <button
            id={selected == 'branchesBtn' ? 'selected' : 'notselected'}
            className='branchesBtn btnDesign'
            onClick={() => navigateButton('branchesBtn')}
          >
            <svg id='dashImg2' xmlns='http://www.w3.org/2000/svg' width='18.157' height='22.196' viewBox='0 0 33.625 33.041'>
              <g id='Icon_feather-check-circle' data-name='Icon feather-check-circle' transform='translate(-1.497 -1.473)'>
                <path
                  id='Path_22'
                  data-name='Path 22'
                  d='M33,16.62V18A15,15,0,1,1,24.105,4.29'
                  fill='none'
                  stroke='#135658'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <path
                  id='Path_23'
                  data-name='Path 23'
                  d='M33,6,18,21.015l-4.5-4.5'
                  fill='none'
                  stroke='#135658'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
              </g>
            </svg>
            Branches
          </button>
          <button
            id={selected == 'prospectiveMembersBtn' ? 'selected' : 'notselected'}
            className='branchesBtn btnDesign'
            onClick={() => navigateButton('prospectiveMembersBtn')}
          >
            <svg id='dashImg2' xmlns='http://www.w3.org/2000/svg' width='18.157' height='22.196' viewBox='0 0 33.625 33.041'>
              <g id='Icon_feather-check-circle' data-name='Icon feather-check-circle' transform='translate(-1.497 -1.473)'>
                <path
                  id='Path_22'
                  data-name='Path 22'
                  d='M33,16.62V18A15,15,0,1,1,24.105,4.29'
                  fill='none'
                  stroke='#135658'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <path
                  id='Path_23'
                  data-name='Path 23'
                  d='M33,6,18,21.015l-4.5-4.5'
                  fill='none'
                  stroke='#135658'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
              </g>
            </svg>
            Prospective Members
          </button>
          <button
            id={selected == 'createReferralBtn' ? 'selected' : 'notselected'}
            className='branchesBtn btnDesign'
            onClick={() => navigateButton('createReferralBtn')}
          >
            <svg id='dashImg2' xmlns='http://www.w3.org/2000/svg' width='18.157' height='22.196' viewBox='0 0 33.625 33.041'>
              <g id='Icon_feather-check-circle' data-name='Icon feather-check-circle' transform='translate(-1.497 -1.473)'>
                <path
                  id='Path_22'
                  data-name='Path 22'
                  d='M33,16.62V18A15,15,0,1,1,24.105,4.29'
                  fill='none'
                  stroke='#135658'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <path
                  id='Path_23'
                  data-name='Path 23'
                  d='M33,6,18,21.015l-4.5-4.5'
                  fill='none'
                  stroke='#135658'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
              </g>
            </svg>
            Referral Links
          </button>
          <button
            id={selected == 'mindbodySetupBtn' ? 'selected' : 'notselected'}
            className='branchesBtn btnDesign'
            onClick={() => navigateButton('mindbodySetupBtn')}
          >
            <svg id='dashImg2' xmlns='http://www.w3.org/2000/svg' width='18.157' height='22.196' viewBox='0 0 33.625 33.041'>
              <g id='Icon_feather-check-circle' data-name='Icon feather-check-circle' transform='translate(-1.497 -1.473)'>
                <path
                  id='Path_22'
                  data-name='Path 22'
                  d='M33,16.62V18A15,15,0,1,1,24.105,4.29'
                  fill='none'
                  stroke='#135658'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <path
                  id='Path_23'
                  data-name='Path 23'
                  d='M33,6,18,21.015l-4.5-4.5'
                  fill='none'
                  stroke='#135658'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
              </g>
            </svg>
            Mindbody Setup
          </button>
        </div>
        <div className='supportContainer'>
          <div className='support'>
            <h4>Support</h4>
            <p>Looking for assistance or just want to provide some feedback?</p>
            <button className='supportBtn' onClick={() => setIsVisible(true)}>
              Contact Support
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
