import '../styles/pages/Contact.css';
import React, { useState, useRef } from 'react';
import { Textbox } from 'react-inputs-validation';
import { contactFormValidation } from '../utils/FormValidationUtils';
import Footer from '../components/common/Footer';
import Navbar from '../components/common/Header';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import validator from 'validator';
import DOMPurify from 'dompurify';

const Contact = () => {
  const [isformMessage, setFormMessage] = useState('');
  const [isformMessageDisplay, setFormMessageDisplay] = useState('none');
  const form = useRef();
  const captchaRef = useRef();
  const [memberFirstName, setMemberFirstName] = useState('');
  const [memberLastName, setMemberLastName] = useState('');
  const [email, setEmail] = useState('');
  const [memberPhoneNumber, setMemberPhoneNumber] = useState('');
  const [website, setWebsite] = useState('');
  const [isValidFirstName, setIsValidFirstName] = useState(true);
  const [isValidLastName, setIsValidLastName] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [error, setError] = useState('');
  const [messageFormText, setMessageFormText] = useState('');
  const [showModal, setShowModal] = useState(false);

  const updateCharacterCount = (event) => {
    const messageText = event.target.value;

    if (messageText.length <= 500) {
      setMessageFormText(messageText);
    }
  };

  // Add the email.js
  const submitEmailForm = async (e) => {
    e.preventDefault();
    const errorMessage = contactFormValidation(e, isValidFirstName, isValidLastName, isValidEmail, isValidPhoneNumber, isValidUrl);

    if (errorMessage) {
      setError(errorMessage);
    } else {
      setError('');
    }

    const token = captchaRef.current.getValue();
    const inputVal = await e.target[0];
    console.log(token);
    captchaRef.current.reset();

    const params = {
      email: e.target.email.value,
      lName: e.target.lName.value,
      fName: e.target.fName.value,
      phoneNumber: e.target.phoneNumber.value,
      website: e.target.website.value,
      customerMessage: DOMPurify.sanitize(e.target.customerMessage.value),
      user_email: 'luke@sharetree.io',
      reply_to: 'luke@sharetree.io',
      'g-recaptcha-response': token,
      'content-type': 'application/json',
    };

    if (!errorMessage) {
      emailjs.send('service_0slj30r', 'template_eodvhhl', params, 'OkvGJhwJDLOAImpFx').then(
        (res) => {
          setShowModal(true);
          resetForm();
        },
        (err) => {
          setFormMessage('Error: Please ensure you have completed the reCAPTCHA correctly.');
          setFormMessageDisplay('block');
          setTimeout(resetFormMessage, 3000);
        }
      );
    }
    captchaRef.current.reset();
  };

  // TODO make this work in the error block above. Seems to be an issue with the timeout not working
  const resetFormMessage = () => {
    setFormMessage('');
  };

  const resetForm = () => {
    setMemberFirstName('');
    setMemberLastName('');
    setEmail('');
    setMemberPhoneNumber('');
    setWebsite('');
    setMessageFormText('');
    setError('');
    setFormMessageDisplay('none');
    setFormMessage('');
  };

  return (
    <div>
      <Navbar />
      <div className='contactMain'>
        <div className='contactContainer'>
          <div className='contactContextContatiner'>
            <div className='contactContext'>
              <img src={require('../assets/images/sharetree-images/Icon awesome-paper-plane.png')} className='mailImage'></img>
              <h1>Let Us Get Back To You.</h1>
              <p>Complete the following form and one of our experts will be in touch with you shortly.</p>
            </div>
          </div>
          <div className='contactForm'>
            <div className='contactFormContext'>
              <form ref={form} className='contactFormInputs' onSubmit={(e) => submitEmailForm(e)}>
                <label>* First Name:</label>
                <Textbox
                  attributesInput={{
                    placeholder: 'John',
                    type: 'text',
                    className: `form-validation-name ${isValidFirstName ? 'newMemberFirstName' : 'error'}`,
                    name: 'fName',
                  }}
                  value={memberFirstName}
                  onChange={(newMemberFirstName, e) => {
                    setMemberFirstName(newMemberFirstName);
                  }}
                  onBlur={(e) => {}}
                  validationOption={{
                    check: true,
                    required: true,
                    name: 'Name',
                    max: 100,
                    customFunc: (newMemberFirstName) => {
                      const reg = /^[A-Za-z\s]+$/; // Capture Alpha numeric only uppercase or lowercase and spaces. It will not capture numbers or special characters
                      if (reg.test(String(newMemberFirstName))) {
                        setIsValidFirstName(true);
                        return true;
                      } else {
                        setIsValidFirstName(false);
                        return 'Not a valid name';
                      }
                    },
                  }}
                ></Textbox>

                <label>* Last Name:</label>
                <Textbox
                  attributesInput={{
                    placeholder: 'Smith',
                    type: 'text',
                    className: `form-validation-name ${isValidLastName ? 'newMemberLastName' : 'error'}`,
                    name: 'lName',
                  }}
                  value={memberLastName}
                  onChange={(newMemberLastName, e) => {
                    setMemberLastName(newMemberLastName);
                  }}
                  onBlur={(e) => {}}
                  validationOption={{
                    check: true,
                    required: true,
                    name: 'Name',
                    max: 100,
                    customFunc: (newMemberLastName) => {
                      const reg = /^[A-Za-z\s]+$/; // Capture Alpha numeric only uppercase or lowercase and spaces. It will not capture numbers or special characters
                      if (reg.test(String(newMemberLastName))) {
                        setIsValidLastName(true);
                        return true;
                      } else {
                        setIsValidLastName(false);
                        return 'Not a valid name';
                      }
                    },
                  }}
                ></Textbox>

                <label>* Email:</label>
                <Textbox
                  attributesInput={{
                    placeholder: 'john.smith@email.com',
                    type: 'text',
                    className: `form-validation-email ${isValidEmail ? 'newMemberEmail' : 'error'}`,
                    name: 'email',
                  }}
                  value={email}
                  onChange={(newMemberEmail, e) => {
                    setEmail(newMemberEmail);
                  }}
                  onBlur={(e) => {}}
                  validationOption={{
                    check: true, //To determine if you need to validate.
                    required: true, // To determine if it is a required field.
                    name: 'Email',
                    customFunc: (newMemberEmail) => {
                      const reg = //checking the email does not contain invalid characters and has the @ symbol followed by a valid domain name
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                      if (reg.test(String(newMemberEmail).toLowerCase())) {
                        setIsValidEmail(true);
                        return true;
                      } else {
                        setIsValidEmail(false);
                        return 'Not a valid email address';
                      }
                    },
                  }}
                ></Textbox>

                <label>* Phone: </label>
                <Textbox
                  attributesInput={{
                    placeholder: '+61412345672',
                    type: 'text',
                    className: `form-validation-mobile ${isValidPhoneNumber ? 'newMemberPhoneNumber' : 'error'}`,
                    name: 'phoneNumber',
                  }}
                  value={memberPhoneNumber}
                  onChange={(newMemberPhoneNumber, e) => {
                    setMemberPhoneNumber(newMemberPhoneNumber);
                  }}
                  onBlur={(e) => {}}
                  validationOption={{
                    check: true,
                    required: true,
                    name: 'Phone Number',
                    customFunc: (newMemberPhoneNumber) => {
                      if (validator.isMobilePhone(newMemberPhoneNumber, 'en-AU')) {
                        setIsValidPhoneNumber(true);
                        return true;
                      } else {
                        setIsValidPhoneNumber(false);
                        return 'Not a valid phone number';
                      }
                    },
                  }}
                ></Textbox>

                <label>* Company Website:</label>
                <Textbox
                  attributesInput={{
                    placeholder: 'www.website.com',
                    type: 'text',
                    className: `form-validation-url ${isValidUrl ? 'newMemberUrl' : 'error'}`,
                    name: 'website',
                  }}
                  value={website}
                  onChange={(newMemberURL, e) => {
                    setWebsite(newMemberURL);
                  }}
                  onBlur={(e) => {}}
                  validationOption={{
                    check: true,
                    required: true,
                    name: 'URL',
                    customFunc: (newMemberURL) => {
                      if (validator.isURL(newMemberURL)) {
                        setIsValidUrl(true);
                        return true;
                      } else {
                        setIsValidUrl(false);
                        return 'Not a valid URL';
                      }
                    },
                  }}
                ></Textbox>

                <label>Message: {messageFormText.length}/500</label>
                <textarea
                  type='text'
                  name='customerMessage'
                  placeholder='What can we help you with? apart from grow your community ;)'
                  onChange={updateCharacterCount}
                  maxLength={500}
                  value={messageFormText}
                />

                <ReCAPTCHA sitekey='6LfMoR0pAAAAAJDtSiV3h5OOeTLFqUg9nuDnW97o' className='recaptcha-css' ref={captchaRef} />
                <input type='submit' value='Submit' className='submitBtn' />
                <p className='formMessage' style={{ display: `${isformMessageDisplay}` }}>
                  {isformMessage}
                </p>
                {error && <p className='errorMessage'>{error}</p>}
                {showModal && (
                  <div className='modal-wrapper'>
                    <div className='modal'>
                      <div className='modal-content'>
                        <p>Thank you for your message! We'll be in touch soon.</p>
                        <button onClick={() => setShowModal(false)}>Close</button>
                      </div>
                    </div>
                    <div className='modal-backdrop'></div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
