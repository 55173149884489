import '../styles/pages/Contact.css';
import React, { useState } from 'react';
import Footer from '../components/common/Footer';
import Navbar from '../components/common/Header';
import { useParams } from 'react-router-dom';
import { addProspectiveMember } from '../services/APIFetchers.js';

const ReferralForm = () => {
  const { business_id } = useParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFail, setIsFail] = useState(false);

  const handleClick = async (e) => {
    e.preventDefault();
    setIsSuccess(false);
    setIsFail(false);
    console.log(e.target.email.value);
    const name = e.target.name.value;
    const email = e.target.email.value;
    try {
      const referralResponse = await addProspectiveMember(
        name,
        email,
        business_id
      );

      if (referralResponse.success) setIsSuccess(true);
      else setIsFail(true);
    } catch (error) {
      console.error('Error creating Referral Link:', error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className='contactMain'>
        <div className='contactContainer'>
          <div className='contactContextContatiner'>
            <div className='contactContext'>
              <img
                src={require('../assets/images/sharetree-images/Icon awesome-paper-plane.png')}
                className='mailImage'
                alt='shareTreeImage'
              ></img>
              <h1>Join your friend</h1>
              <div>
                <form
                  className='contactFormInputs'
                  onSubmit={(e) => handleClick(e)}
                >
                  <label>Name:</label>
                  <input
                    type='text'
                    name='name'
                    placeholder='First and last name'
                  />
                  <label>Email:</label>
                  <input
                    type='text'
                    name='email'
                    placeholder='john.smith@email.com'
                  />
                  <button type='submit' className='submit-login'>
                    Submit
                  </button>
                </form>
                {isSuccess && <p>Successfully registered!</p>}
                {isFail && (
                  <p>
                    Something went wrong! Please try again. If this continues to
                    occur, the link may be expired.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ReferralForm;
