import '../memberTable/MemberTable.css';
import React, { useEffect, useState } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { auth, getData } from '../../services/Auth';
import { onAuthStateChanged } from 'firebase/auth';

const ReferralLinksTable = () => {
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState('');
  const [members, setMembers] = useState([]);

  // TODO: Maybe make more efficient in future by allowing only a single member to get fetched.
  const loadMembersDetails = async () => {
    setLoading(true);
    getData(`users/${auth.currentUser.uid}/members`)
      .then((data) => {
        if (data) {
          var userMembers = Object.entries(data).map(([key, value]) => ({
            id: key,
            ...value,
          }));

          const newData = userMembers.map((member) => {
            return {
              id: member.id,
              memberName: member.memberName,
              memberEmail: member.memberEmail,
              referralLink: member.referralLink,
            };
          });
          setMembers(newData);
        } else {
          setMembers([]);
          setNoData('Add your first tree and start growing your business!');
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setNoData(err);
      });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) loadMembersDetails();
    });
  }, []);

  // TODO: Send email
  const sendEmailWithReferralLink = (member) => {};

  return (
    <div>
      <div className='memberTable'>
        <div className='tree-heading'>
          <h3>Referral Links</h3>
          <p>View the referral links used by your members.</p>
        </div>
        <table className='styled-table'>
          <thead>
            <tr className='titles'>
              <th>Name</th>
              <th>Email</th>
              <th>Referral Link</th>
            </tr>
          </thead>

          {loading ? (
            <tbody>
              <tr className='loading'>
                <td>
                  <PropagateLoader
                    className='loadingSpinner'
                    loading={loading}
                    color='#00bc64'
                    size={15}
                    speedMultiplier={2}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody className='active-row'>
              {members.length > 0 ? (
                Array.from(members).map((user) => (
                  <tr key={user.id}>
                    <td>{user.memberName}</td>
                    <td>{user.memberEmail}</td>
                    <td>
                      {user.referralLink !== undefined ? (
                        <a
                          href={`https://${window.location.hostname}/referral/${user.referralLink}`}
                        >
                          {user.referralLink}
                        </a>
                      ) : (
                        'Referral link does not exist for this member'
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='5'>
                    <p className='addFirstTree'>{noData.toString()}</p>
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default ReferralLinksTable;
