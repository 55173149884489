import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/components/common/Footer.css';
import PrivacyPolicy from '../../pages/PrivacyPolicy';

function Footer() {
  return (
    <div>
      <div className='getStartedCTA'>
        <div className='getStartedContext'>
          <h2>Get started with Sharetree today</h2>
          <h6>Try our powerful referral and retention software for memberships</h6>
          <button className='ctaFooter'>Try Free</button>
        </div>
      </div>
      <div className='footer'>
        <div className='footerContextContainer'>
          <div className='footerContext'>
            <div className='fContext'>
              <div className='footerLogo'>
                <svg id='Sharetree_Logos'></svg>
              </div>
              <div className='socialIcons'>
                <a href='https://twitter.com/ShareTree_io' target={'_blank'}>
                  <svg id='socialIcon1'></svg>
                </a>
                <a href='https://www.instagram.com/' target={'_blank'}>
                  <svg id='socialIcon2'></svg>
                </a>
                <a href='https://www.instagram.com/' target={'_blank'}>
                  <svg id='socialIcon3'></svg>
                </a>
              </div>
            </div>
          </div>
          <div className='noticesAndLegal'>
            <div className='copyrightContext'>
              <p>© Copyright 2023 | Sharetree</p>
            </div>
            <div className='legal'>
              <a href='www.sharetree.io/contact'>
                <p>Support</p>
              </a>
              <Link to='privacyPolicy'>
                <p>Privacy Policy</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
