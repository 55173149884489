import '../styles/pages/Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faUsers } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Branches from './Branches';
import SideBar from '../components/sideBar/SideBar';
import BugForm from '../components/sideBar/BugForm';

const Dashboard = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  //   Font Awesome Icons
  const homeIcon = <FontAwesomeIcon className='' icon={faHouse} />;
  const branchesIcon = <FontAwesomeIcon className='' icon={faUsers} />;

  return (
    <div className='dashboardContainer'>
      <SideBar setIsVisible={setIsVisible} />

      <div className='componentContainer'>
        <Branches />
        <BugForm isVisible={isVisible} setIsVisible={setIsVisible} />
        {/*  
       
        <div className="componentStorage">
          <div className="navContainer"></div>
          <div className="containerForComponents">
            <div className="memberTableContainer">
              <div className="memberTableContextContainer">
                <div className="tableHeadingingContext">
                  <h3>Current Trees</h3>
                  <p>View and manage your current trees and their branches</p>
                </div>
                <button className="dropDownSelector">
                  dropdown
                  <img
                    id="btnImg"
                    src={require("../assets/images/sharetree-images/Icon ionic-ios-arrow-down@2x.png")}
                  ></img>
                </button>
              </div>
              <div className="memberTableComp">
              
              </div>
            </div>
            <div className="individualMembersContainer">
              <div className="addBranchComp"></div>
              <div className="selectedMemberContainer"></div>
            </div>
          </div>
        </div>
      */}
      </div>

      {/* {showNavbar && <Navbar />}
      <nav className='dashboard-nav'>
        <div className='dashboard-logo'>
          <h2 className='sharetree-logo'>Sharetree.io</h2>
        </div>

        <div className='login-navbar-container'>
          <h4 className='login-text'>
            <button className='name-btn' onClick={handleNameClick}>
              Hello, {username} {/** this needs to be the username}
            </button>
          </h4>
        </div>
      </nav>
      <div></div>
      <AccountSidebar auth={auth} />
      <div className='dashboard-section'>
        {/* TODO: This needs to change based on the selected button }
        {/* TODO: Main screen is the table ADD ROUTES}
      <div className="add-member-discount-container">
        <AddTree />
      </div>
      <MemberTable
        handleClick={(e) => {
          setIsClicked(true);
        }}
      />
      {/* TODO: <DeleteBranch
          // gets the user selected and fetches the data from firebase
          isClicked={isClicked}
          setIsClicked={(e) => setIsClicked(false)}
          onClick={"hey"}
        /> }
      </div> */}
    </div>
  );
};

export default Dashboard;
