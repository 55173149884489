import "../styles/pages/Login.css";

import React, { Component, useState } from "react";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { auth } from "../services/Auth";
import { Link, useNavigate } from "react-router-dom";

import Footer from "../components/common/Footer";
import Navbar from "../components/common/Header";
import PropagateLoader from "react-spinners/PropagateLoader";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // GOOGLE LOGIN
  const googleLogin = () => {
    console.log("hey");
  };

  //   button handler to get the values on email and password
  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);
    // this logs a user into dashboard
    if (email == "" && password == "") {
      setErrorMessage("Please add to email and password fields");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);

      setLoading(false);
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // onAuthStateChanged(auth, (user) => {
          if (userCredential) {
            const uid = userCredential.uid;
            // writeData(uid, fullName, email, password);
            // redirects to Dashboard
            setLoading(false);
            console.log(`User: ${userCredential} ${uid} is signed in.`);
            navigate("/dashboard");
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          setLoading(false);
          setErrorMessage("Password or username does not match any user");

          setTimeout(() => {
            setErrorMessage("");
            setEmail("");
            setPassword("");
          }, 3000);

          console.log(`Account doesnt exist ${error}`);
        });
    }
  };

  return (
    <div className="loginContainer">
      {/* <Navbar /> */}
      <div className="dashboard-login">
        <div className="loginCreds">
          <div className="loginCredsContainer">
            <h3 className="sharetreeLoginHeader">Log In</h3>

            {/* DISABLING GOOGLE LOGIN FOR NOW! NEED TO ADD THIS BACK IN*/}
            {/* <div className="googleLogin">
              <button className="googleSigninBtn" onClick={googleLogin}>
                <img
                  src={require("../assets/images/sharetree-images/google-logo-icon-PNG-Transparent-Background-letter-G-multiple-colors.png")}
                />{" "}
                Sign in with google
              </button>
              <div className="spacer">
                <div className="spacerLine1"></div>
                <p>or</p>
                <div className="spacerLine2"></div>
              </div>
            </div> */}

            <div className="login-user">
              <form onSubmit={handleClick}>
                <label>Email:</label>
                <input
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  className="inputs emails"
                ></input>
                <label>Password:</label>
                <input
                  type="Password"
                  required
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  className="inputs password"
                ></input>
                <p className="warningError">{errorMessage}</p>
                <button
                  type="submit"
                  onClick={handleClick}
                  className="submit-login"
                >
                  Log in
                </button>
              </form>
              {/* <div className="noAccountContainer"> */}
              <p className="dont-have-account">
                New to Sharetree? <a href="/signup">Create a free account</a>
              </p>
              {/* </div> */}
            </div>
            <div className="loaderBar">
              <PropagateLoader
                loading={loading}
                color="#00bc64"
                size={15}
                speedMultiplier={2}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="loginColorContainer">
        <div className="colorContextContainer">
          <Link to={"/"}>
            <svg id="SharetreeLogoWhite"></svg>
          </Link>
          <div className="coloredHeading">
            <h1>Welcome Back!</h1>
            <p>
              We've been expecting you. Sign in to pickup where you left off.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
