import '../memberTable/MemberTable.css';
import React, { useEffect, useState } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { auth, getData, writeData, removeData } from '../../services/Auth';
import { onAuthStateChanged } from 'firebase/auth';
import { isMemberManagedByIntegration } from '../../services/UsefulFunctions';

const ProspectiveTable = ({ setIsApproveFormVisible }) => {
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState('');
  const [prospectiveMembers, setProspectiveMembers] = useState([]);

  // TODO: Maybe make more efficient in future by allowing only a single member to get fetched.
  const loadMembersDetails = async () => {
    setLoading(true);
    getData(`prospectiveMembers/${auth.currentUser.uid}`)
      .then((data) => {
        if (data) {
          var userMembers = Object.entries(data).map(([key, value]) => ({
            id: key,
            ...value,
          }));

          const newData = userMembers.map((member) => {
            return {
              id: member.id,
              managedByIntegration: isMemberManagedByIntegration({ mindbodyId: member.isMindbody }),
              memberName: member.memberName,
              memberEmail: member.prospectiveEmail,
            };
          });
          setProspectiveMembers(newData);
        } else {
          setProspectiveMembers([]);
          setNoData('You have no more prospective members to review. Please come back later.');
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setNoData(err);
      });
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        await loadMembersDetails();
      }
    });
  }, []);

  const approveProspective = async (prospectiveMember) => {
    const referringMemberId = await getData(`prospectiveMembers/${auth.currentUser.uid}/${prospectiveMember.id}/memberId`);
    const referringMember = await getData(`users/${auth.currentUser.uid}/members/${referringMemberId}`);

    setIsApproveFormVisible({
      referringMember: referringMember,
      prospectiveMember: prospectiveMember,
    });
  };

  const deleteProspectiveMember = async (prospectiveMember) => {
    try {
      await removeData(`prospectiveMembers/${auth.currentUser.uid}/${prospectiveMember.memberEmail}`);
    } catch (error) {
      throw error;
    }
    loadMembersDetails();
  };

  return (
    <div>
      <div className='memberTable'>
        <div className='tree-heading'>
          <h3>Prospective Members</h3>
          <p>View and manage your prospective members.</p>
        </div>
        <table className='styled-table'>
          <thead>
            <tr className='titles'>
              <th>Name</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>

          {loading ? (
            <tbody>
              <tr className='loading'>
                <td>
                  <PropagateLoader
                    className='loadingSpinner'
                    loading={loading}
                    color='#00bc64'
                    size={15}
                    speedMultiplier={2}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody className='active-row'>
              {prospectiveMembers.length > 0 ? (
                Array.from(prospectiveMembers).map((user) => (
                  <tr key={user.id}>
                    <td>{user.memberName}</td>
                    <td>{user.memberEmail}</td>
                    <td className='table-buttons'>
                      {user.managedByIntegration == undefined ? (
                        <div>
                          <button className='table-add-button' onClick={() => approveProspective(user)}>
                            Approve
                          </button>
                          <button className='table-remove-button' onClick={() => deleteProspectiveMember(user)}>
                            Remove
                          </button>
                        </div>
                      ) : (
                        <p className='table-member-integration-status'>Managed by {user.managedByIntegration}</p>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='5'>
                    <p className='addFirstTree'>{noData.toString()}</p>
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default ProspectiveTable;
