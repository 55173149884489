import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, getIdToken } from 'firebase/auth';
import '../../styles/pages/Branches.css';
import '../../components/memberTable/MemberTable.css';
import { auth, getData, updateData } from '../../services/Auth';
import {
  getMindBodyAuth,
  getMindbodyMembers,
  setMindBodyDiscountsBasic,
  removeMindbodyIntegration,
  syncWithMindbody,
} from '../../services/APIFetchers';
import SideBar from '../../components/sideBar/SideBar';
import TopBar from '../../components/topBar/topBar';
import { getLocalDateFromString } from '../../services/UsefulFunctions';

const getSavedProgress = async () => {
  const stage = await getData(`users/${auth.currentUser.uid}/mindbodyIntegrationStage`);
  return Number(stage) || 1;
};

const getSavedDiscounts = async () => {
  return await getData(`users/${auth.currentUser.uid}/mindbodyDiscounts`);
};

const getLastSyncDate = async () => {
  const date = await getData(`users/${auth.currentUser.uid}/mindbodyAccount/lastMindbodySync`);
  if (date) return getLocalDateFromString(date);
  else return null;
};

const getProcessState = async () => {
  return await getData(`users/${auth.currentUser.uid}/mindbodyAccount/mindbodyProcessState`);
};

const saveProgress = (stage) => {
  updateData(`users/${auth.currentUser.uid}`, {
    mindbodyIntegrationStage: stage,
  });
};

// TODO: This username/password flow MUST be changed with OAUTH flow once deployed for real.
// TODO: Make stages an enum
const MindbodySetup = () => {
  const [firebaseToken, setFirebaseToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tempProcessState, setTempProcessState] = useState(undefined);
  const [lastSync, setLastSync] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState();
  const [stage, setStage] = useState(null);
  const [formDiscounts, setFormDiscounts] = useState(undefined);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        await Promise.all([getIdToken(user, true), getSavedProgress(), getSavedDiscounts(), getLastSyncDate(), getProcessState()]).then((resp) => {
          setFirebaseToken(resp[0]);
          setStage(resp[1]);
          setFormDiscounts(resp[2]);
          setLastSync(resp[3]);
          setTempProcessState(resp[4]);
        });
        setIsLoading(false);
      }
    });
  }, []);

  const waitForProcessToFinish = async () => {
    while (true) {
      const processState = await getProcessState();
      setTempProcessState(processState);
      if (processState == undefined || processState.includes('fail')) break;
      else await new Promise((resolve) => setTimeout(resolve, 10000));
    }
  };

  const preventFormReloadAndResetSomeState = (form) => {
    if (form) form.preventDefault();
    setIsLoading(true);
    setErrorMessage(null);
    setTempProcessState(undefined);
  };

  const verifyMindbodyAccount = async (form) => {
    preventFormReloadAndResetSomeState(form);
    const target = form.target;
    const username = target.username.value;
    const password = target.password.value;
    const siteId = target.siteId.value;
    // save username/password in firebase.
    await getMindBodyAuth(username, password, siteId, firebaseToken)
      .then((staffToken) => {
        if ('staffToken' in staffToken) {
          setStage(2);
          saveProgress(2);
        } else {
          setErrorMessage('Invalid credentials provided');
        }
      })
      .catch((e) => {
        setErrorMessage(JSON.stringify(e));
      });

    setIsLoading(false);
  };

  const importMembers = async (form) => {
    preventFormReloadAndResetSomeState(form);
    // This operation can take a while, hence the "wait for process to finish"
    await getMindbodyMembers(firebaseToken).catch((e) => {
      setErrorMessage(JSON.stringify(e));
    });

    await waitForProcessToFinish();

    setStage(await getSavedProgress());
    setIsLoading(false);
  };

  const saveDiscounts = async (form) => {
    preventFormReloadAndResetSomeState(form);
    const target = form.target;
    const discountForReferee = target.discountForReferee.value || 0;
    const discountOnReferral = target.discountOnReferral.value;
    if (discountForReferee < 0 || discountOnReferral < 0.01) {
      setErrorMessage('Please provide valid values.');
      return;
    }
    await setMindBodyDiscountsBasic(firebaseToken, discountOnReferral, discountForReferee)
      .then(async (resp) => {
        if ('success' in resp) {
          setFormDiscounts(await getSavedDiscounts());
          setStage(4);
          saveProgress(4);
        } else setErrorMessage('Something went wrong');
      })
      .catch((e) => {
        setErrorMessage(JSON.stringify(e));
      });

    setIsLoading(false);
  };

  const removeIntegration = async (form) => {
    preventFormReloadAndResetSomeState(form);
    await removeMindbodyIntegration(firebaseToken)
      .then((resp) => {
        if ('success' in resp) {
          setStage(1);
          setFormDiscounts(undefined);
        } else setErrorMessage('Something went wrong');
      })
      .catch((e) => {
        setErrorMessage(JSON.stringify(e));
      });

    setIsLoading(false);
  };

  const sync = async (form) => {
    preventFormReloadAndResetSomeState(form);
    // This operation can take a while, hence the "wait for process to finish"
    await syncWithMindbody(firebaseToken).catch((e) => {
      setErrorMessage(JSON.stringify(e));
    });

    await waitForProcessToFinish();

    setLastSync(await getLastSyncDate());
    setIsLoading(false);
  };

  return (
    <div className='dashboardContainer'>
      <SideBar setIsVisible={false} />
      <div className='componentContainer'>
        <div className='branchContainer'>
          <div className='componentContainers'>
            <TopBar />
            <div className='memberDetailContainer'>
              <div className='memberTable'>
                <div className='tree-heading'>
                  <h3>Mindbody Integration</h3>
                  <p>Automate the referral process for you and your members.</p>
                </div>
                <div className='add-btn-text'>
                  <h3>Setup Integration</h3>
                  {stage === 1 && !isLoading && (
                    <form onSubmit={async (e) => await verifyMindbodyAccount(e)}>
                      <label htmlFor='newMemberEmail'>Mindbody username:</label>
                      <input className='inputs' name='username' required placeholder='Enter details...'></input>
                      <br />
                      <label htmlFor='newMemberEmail'>Mindbody password:</label>
                      <input type='password' name='password' className='inputs' required placeholder='Enter details...'></input>
                      <br />
                      <label htmlFor='newMemberEmail'>Mindbody Site ID:</label>
                      <input name='siteId' className='inputs' required placeholder='Enter details...'></input>
                      <br />
                      <button type='submit'>Submit</button>
                    </form>
                  )}
                  {stage === 2 && !isLoading && (
                    <form onSubmit={async (e) => await importMembers(e)}>
                      <p>Successfully validated credentials!</p>
                      <p>We will now import your Mindbody members into our system.</p>
                      <p>To protect the privacy of your members, we are only collecting their user ID, full name, and email address.</p>
                      <button type='submit'>Import members</button>
                    </form>
                  )}
                  {(stage === 3 || stage === 4) && !isLoading && (
                    <form onSubmit={async (e) => await saveDiscounts(e)}>
                      {stage === 3 && <p>Successfully imported your members!</p>}
                      {stage === 4 && (
                        <p>
                          <b>Your integration is fully configured! Come back here anytime to modify your discounts.</b>
                        </p>
                      )}
                      {formDiscounts != undefined && (
                        <p>
                          <b>Current Discounts Set:</b>
                          <br />
                          Referral: ${formDiscounts.discountOnReferral}
                          <br />
                          Referee: ${formDiscounts.discountForReferee}
                        </p>
                      )}
                      <p>
                        <b>Please determine your discount offerings.</b>
                      </p>
                      <label>Member Discount per Referral ($):</label>
                      <input
                        type='number'
                        name='discountOnReferral'
                        min='0.01'
                        step='any'
                        required
                        className='inputs'
                        placeholder='Please enter a number greater than 0.01'
                      ></input>
                      <p>
                        <b>For better referral performance, you can (optionally) provide referred members with a one-time discount.</b>
                      </p>
                      <label>One-Time Referee Discount ($):</label>
                      <input
                        type='number'
                        name='discountForReferee'
                        min='0'
                        step='any'
                        className='inputs'
                        placeholder='Please enter 0 if this discount is undesired'
                      ></input>
                      <button type='submit'>Save Discount Settings</button>
                    </form>
                  )}
                  {stage > 1 && !isLoading && (
                    <button type='button' onClick={async (e) => await removeIntegration(e)}>
                      Remove integration
                    </button>
                  )}
                  {stage == 4 && !isLoading && (
                    <div>
                      <button type='button' onClick={async (e) => await sync(e)}>
                        Sync with mindbody
                      </button>
                      <p>Note: This will remove old members, add new members, manage branches, and apply discounts.</p>
                      <p>
                        <b>Last sync: </b>
                        {lastSync == undefined ? 'N/A' : lastSync}
                      </p>
                    </div>
                  )}
                  {errorMessage && <p>{errorMessage}</p>}
                  {(isLoading || stage === null) && (
                    <p class='animatedLoading'>{tempProcessState == 'running' ? 'Still working on it, thanks for your patience' : 'Loading'}</p>
                  )}
                  {tempProcessState != undefined && tempProcessState.includes('fail') && (
                    <p>
                      <b>Failed at:</b> {getLocalDateFromString(tempProcessState.split(' ')[1])}
                      <br />
                      If this continues to occur, please reach out to Sharetree for support.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MindbodySetup;
