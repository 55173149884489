import './MemberTable.css';
import React, { useEffect, useState } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { auth, getData, updateData, removeData } from '../../services/Auth';
import { onAuthStateChanged, getIdToken } from 'firebase/auth';
import { isMemberManagedByIntegration } from '../../services/UsefulFunctions';
import { removeReferralLink } from '../../services/APIFetchers';

const MemberTable = ({ setIsAddBranchFormVisible }) => {
  const [firebaseToken, setFirebaseToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState('');
  const [members, setMembers] = useState([]);

  // TODO: Maybe make more efficient in future by allowing only a single member to get fetched.
  const loadMembersDetails = async () => {
    setLoading(true);
    const mindbodyDiscounts = await getData(`users/${auth.currentUser.uid}/mindbodyDiscounts`);
    getData(`users/${auth.currentUser.uid}/members`)
      .then((data) => {
        if (data) {
          var userMembers = Object.entries(data).map(([key, value]) => ({
            id: key,
            ...value,
          }));

          const newData = userMembers.map((member) => {
            // Making things compatible if there are both mindbodyMembers and manual members in account
            let isManagedByIntegration = isMemberManagedByIntegration(member);
            let discount = isManagedByIntegration ? mindbodyDiscounts.discountOnReferral : Number(member.discount);
            return {
              id: member.id,
              memberName: member.memberName,
              memberEmail: member.memberEmail,
              branches: Number(member.branches),
              branchDetails: member.branchDetails,
              membersMembership: Number(member.membershipPrice),
              newPrice: isManagedByIntegration ? member.membershipPrice : Math.max(0, Number(member.membershipPrice - discount * member.branches)),
              discount: discount,
              referralLink: member.referralLink,
              managedByIntegration: isMemberManagedByIntegration(member),
            };
          });
          setMembers(newData);
        } else {
          setMembers([]);
          setNoData('Add your first tree and start growing your business!');
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setNoData(err);
      });
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setFirebaseToken(await getIdToken(user, true));
        loadMembersDetails();
      }
    });
  }, []);

  const deleteBranch = async (referringMember, branchMemberDelete) => {
    const referringMemberDBPath = `users/${auth.currentUser.uid}/members/${referringMember.id}`;
    await removeData(`${referringMemberDBPath}/branchDetails/${branchMemberDelete.id}`).then(async () => {
      await updateData(`${referringMemberDBPath}`, {
        branches: referringMember.branches - 1,
      });
    });
  };

  const deleteTree = async (treeDelete) => {
    members.map(async (member) => {
      if (member.branchDetails && treeDelete.id in member.branchDetails) {
        await deleteBranch(member, treeDelete);
      }
    });
    await removeData(`users/${auth.currentUser.uid}/members/${treeDelete.id}`);
    console.log(treeDelete.referralLink);
    await removeReferralLink(firebaseToken, treeDelete.referralLink);
    loadMembersDetails();
  };

  return (
    <div>
      <div className='memberTable'>
        <div className='tree-heading'>
          <h3>Current Trees</h3>
          <p>View and manage your trees and their branches.</p>
        </div>
        <table className='styled-table'>
          <thead>
            <tr className='titles'>
              <th>Member</th>
              <th>Branches</th>
              <th>Membership</th>
              <th>Status</th>
              <th>Discount</th>
              <th>Actions</th>
            </tr>
          </thead>

          {loading ? (
            <tbody>
              <tr className='loading'>
                <td>
                  <PropagateLoader
                    className='loadingSpinner'
                    loading={loading}
                    color='#00bc64'
                    size={15}
                    speedMultiplier={2}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody className='active-row'>
              {members.length > 0 ? (
                Array.from(members).map((user) => (
                  <tr key={user.id}>
                    <td>{user.memberName}</td>
                    <td>{user.branches}</td>
                    <td>${user.newPrice}</td>
                    <td className='active-account'>Active</td>
                    <td>${user.discount}</td>
                    <td className='table-buttons'>
                      {user.managedByIntegration == undefined ? (
                        <div>
                          <button className='table-add-button' onClick={() => setIsAddBranchFormVisible(user)}>
                            Add Branch
                          </button>
                          <button className='table-remove-button' onClick={() => deleteTree(user)}>
                            Remove Tree
                          </button>
                        </div>
                      ) : (
                        <p className='table-member-integration-status'>Managed by {user.managedByIntegration}</p>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='6'>
                    <p className='addFirstTree'>{noData.toString()}</p>
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default MemberTable;
