// This is where all the authentication will happen for firebase authentication
// Import the functions you need from the SDKs you need

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  getDatabase,
  ref,
  set,
  get,
  push,
  update,
  child,
  remove,
} from 'firebase/database';
import { getAnalytics } from 'firebase/analytics';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_API_KEY}`, //MAKE THIS A .ENV KEY
  authDomain: 'sharetreeio.firebaseapp.com',
  databaseURL: 'https://sharetreeio-default-rtdb.firebaseio.com',
  projectId: 'sharetreeio',
  storageBucket: 'sharetreeio.appspot.com',
  messagingSenderId: '906243874001',
  appId: '1:906243874001:web:59a2387a7b191198d6b9f0',
  measurementId: 'G-EFBRPNEJMT',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const database = getDatabase(app);

export const getData = async (path) => {
  const dbRef = ref(database);
  return await get(child(dbRef, path.replaceAll('.', '')))
    .then((snapshot) => snapshot.val())
    .catch((error) => console.error(error));
};

export const writeData = async (
  path,
  fields,
  error_message = 'Failed to save data: '
) => {
  await set(ref(database, path.replaceAll('.', '')), fields).catch((error) =>
    console.error(error_message, error)
  );
};

// More correct to use when just updating fields instead of setting new ones
export const updateData = async (
  path,
  fields,
  error_message = 'Failed to update data: '
) => {
  await update(ref(database, path.replaceAll('.', '')), fields).catch((error) =>
    console.error(error_message, error)
  );
};

export const pushData = async (
  path,
  fields,
  error_message = 'Failed to create new entry: '
) => {
  await push(ref(database, path.replaceAll('.', '')), fields).catch((error) =>
    console.error(error_message, error)
  );
};

export const removeData = async (
  path,
  fields,
  error_message = 'Failed to remove entry: '
) => {
  await remove(ref(database, path.replaceAll('.', '')), fields).catch((error) =>
    console.error(error_message, error)
  );
};
