export function addTreeFormValidation(e, isValidMemberName, isValidEmail, isValidMemberPrice, isValidDiscount) {
  const validationChecks = [
    {
      condition: !e.target.to_name.value,
      errorMessage: 'Please fill in all required fields',
    },
    {
      condition: !e.target.user_email.value,
      errorMessage: 'Please fill in all required fields',
    },
    {
      condition: !e.target.currMembership.value,
      errorMessage: 'Please fill in all required fields',
    },
    {
      condition: !e.target.discount.value,
      errorMessage: 'Please fill in all required fields',
    },
    {
      condition: !isValidMemberName,
      errorMessage: 'Invalid Name',
    },
    {
      condition: !isValidEmail,
      errorMessage: 'Invalid Email',
    },
    {
      condition: !isValidMemberPrice,
      errorMessage: 'Invalid Price Value',
    },
    {
      condition: !isValidDiscount,
      errorMessage: 'Invalid Discount Value',
    },
  ];

  for (const check of validationChecks) {
    if (check.condition) {
      return check.errorMessage;
    }
  }

  return null;
}

export function contactFormValidation(e, isValidFirstName, isValidLastName, isValidEmail, isValidPhoneNumber, isValidUrl) {
  const validationChecks = [
    {
      condition: !e.target.fName.value,
      errorMessage: 'Please fill in all required fields',
    },
    {
      condition: !e.target.lName.value,
      errorMessage: 'Please fill in all required fields',
    },
    {
      condition: !e.target.email.value,
      errorMessage: 'Please fill in all required fields',
    },
    {
      condition: !e.target.phoneNumber.value,
      errorMessage: 'Please fill in all required fields',
    },
    {
      condition: !e.target.website.value,
      errorMessage: 'Please fill in all required fields',
    },
    {
      condition: !isValidFirstName,
      errorMessage: 'Invalid Name',
    },
    {
      condition: !isValidLastName,
      errorMessage: 'Invalid Name',
    },
    {
      condition: !isValidEmail,
      errorMessage: 'Invalid Email',
    },
    {
      condition: !isValidPhoneNumber,
      errorMessage: 'Invalid Phone Number',
    },
    {
      condition: !isValidUrl,
      errorMessage: 'Invalid URL',
    },
  ];

  for (const check of validationChecks) {
    if (check.condition) {
      return check.errorMessage;
    }
  }

  return null;
}
