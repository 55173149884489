import React, { useRef, useState } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { Textbox } from 'react-inputs-validation';
import { addTreeFormValidation } from '../../utils/FormValidationUtils';
import './AddTree.css';
import '../memberTable/MemberTable.css';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import './InputValidation.css';

const AddTreeOrBranchForm = ({ setToggle, submitForm, branchFor = undefined, prospective = undefined }) => {
  const form = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [memberName, setMemberName] = useState('');
  const [memberPrice, setMemberPrice] = useState('');
  const [memberDiscount, setMemberDiscount] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidMemberPrice, setIsValidMemberPrice] = useState(true);
  const [isValidMemberName, setIsValidMemberName] = useState(true);
  const [isValidDiscount, setIsValidDiscount] = useState(true);
  const isAddingBranch = branchFor !== undefined;
  const [error, setError] = useState('');

  const showBranches = () => {
    const branches = [];
    for (const key in branchFor.branchDetails) {
      branches.push(branchFor.branchDetails[key].branchName);
    }
    return branches.length > 0 ? branches : ['No branches yet'];
  };

  const extractFieldsAndSubmit = async (e) => {
    e.preventDefault();
    const errorMessage = addTreeFormValidation(e, isValidMemberName, isValidEmail, isValidMemberPrice, isValidDiscount);

    if (errorMessage) {
      setError(errorMessage);
      return;
    }
    setIsLoading(true);
    const member = {
      memberName: e.target.to_name.value.trim(),
      branches: 0,
      branchDetails: null,
      memberEmail: e.target.user_email.value,
      membershipPrice: e.target.currMembership.value,
      discount: e.target.discount.value,
    };
    if (prospective) branchFor = prospective.referringMember;
    submitForm(member, form, branchFor, prospective);
  };

  return (
    <>
      <div
        className='backgroundBlur'
        onClick={(e) => {
          setToggle(false);
        }}
      ></div>
      <div className='background-container'>
        <div className='memberInform'>
          <div className='formHeading'>
            {!isAddingBranch ? <h3>Start Growing</h3> : <h3>Add a New Branch to {branchFor.memberName || prospective.referringMember.memberName}</h3>}
            <button
              className='closeFormBtn'
              onClick={(e) => {
                setToggle(false);
              }}
            >
              X
            </button>
          </div>
          {!isAddingBranch ? (
            <p className='formPara'>Add a current member that wants to start growing your business.</p>
          ) : (
            <p className='formPara'>
              A discount will be applied to <b>{branchFor.memberName || prospective.referringMember.memberName}</b>
              .
              <br /> This branch will also be added as a new tree.
            </p>
          )}
          <div className='formContainer'>
            <form ref={form} className='newMemberForm' onSubmit={(e) => extractFieldsAndSubmit(e)}>
              <label htmlFor='newMemberName'>Member name:</label>
              <Textbox
                attributesInput={{
                  placeholder: 'Member Name',
                  type: 'text',
                  className: `form-validation-name ${isValidMemberName ? 'newMemberName' : 'error'}`,
                  defaultValue: prospective && prospective.prospectiveMember.memberName,
                  name: 'to_name', // EmailJS Name
                }}
                value={memberName}
                onChange={(newMemberName, e) => {
                  setMemberName(newMemberName);
                }}
                onBlur={(e) => {}}
                validationOption={{
                  check: true,
                  required: true,
                  name: 'Name',
                  max: 100,
                  customFunc: (newMemberName) => {
                    const reg = /^[A-Za-z\s]+$/; // Capture Alpha numeric only uppercase or lowercase and spaces. It will not capture numbers or special characters
                    if (reg.test(String(newMemberName))) {
                      console.log(newMemberName);
                      setIsValidMemberName(true);
                      return true;
                    } else {
                      setIsValidMemberName(false);
                      return 'Not a valid name';
                    }
                  },
                }}
              ></Textbox>
              <label htmlFor='newMemberEmail'>Email:</label>
              <Textbox
                attributesInput={{
                  placeholder: 'Email',
                  type: 'text',
                  className: `form-validation-email ${isValidEmail ? 'newMemberEmail' : 'error'}`,
                  defaultValue: prospective && prospective.prospectiveMember.memberEmail,
                  name: 'user_email',
                }}
                value={email}
                onChange={(newEmail, e) => {
                  setEmail(newEmail);
                }}
                onBlur={(e) => {}}
                validationOption={{
                  check: true, //To determine if you need to validate.
                  required: true, // To determine if it is a required field.
                  name: 'Email',
                  customFunc: (newEmail) => {
                    const reg = //checking the email does not contain invalid characters and has the @ symbol followed by a valid domain name
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (reg.test(String(newEmail).toLowerCase())) {
                      console.log(newEmail);
                      setIsValidEmail(true);
                      return true;
                    } else {
                      setIsValidEmail(false);
                      return 'Not a valid email address';
                    }
                  },
                }}
              ></Textbox>
              <label htmlFor='currentMembership'>Current Membership ($):</label>
              <Textbox
                attributesInput={{
                  placeholder: 'e.g. 70',
                  type: 'text',
                  className: `form-validation-price ${isValidMemberPrice ? 'newMemberPrice' : 'error'}`,
                  name: 'currMembership', // EmailJS Current Membership
                }}
                value={memberPrice}
                onChange={(newMemberPrice, e) => {
                  setMemberPrice(newMemberPrice);
                }}
                onBlur={(e) => {}}
                validationOption={{
                  check: true,
                  required: true,
                  name: 'Membership Price',
                  customFunc: (newMemberPrice) => {
                    const reg = //checking that there are no letters or special characters. It should only allow numbers
                      /^\d+(\.\d{1,2})?$/;
                    if (reg.test(String(newMemberPrice))) {
                      setIsValidMemberPrice(true);
                      return true;
                    } else {
                      setIsValidMemberPrice(false);
                      return 'Not a valid amount';
                    }
                  },
                }}
              ></Textbox>
              <label htmlFor='branch'></label>
              <input style={{ display: 'none' }} type='number' placeholder='0' className='branch'></input>
              <label htmlFor='discount'>Discount Per Referral ($):</label>
              <Textbox
                attributesInput={{
                  placeholder: 'e.g. 1.50',
                  type: 'text',
                  className: `form-validation-discount ${isValidDiscount ? 'discount' : 'error'}`,
                  name: 'discount', // EmailJS Discount
                }}
                value={memberDiscount}
                onChange={(newMemberDiscount, e) => {
                  setMemberDiscount(newMemberDiscount);
                }}
                onBlur={(e) => {}}
                validationOption={{
                  check: true,
                  required: true,
                  name: 'Membership Discount',
                  customFunc: (newMemberDiscount) => {
                    const reg = //checking that there are no letters or special characters and it allows for 2 numbers after the decimal point
                      /^\d+(\.\d{1,2})?$/;
                    if (reg.test(String(newMemberDiscount))) {
                      setIsValidDiscount(true);
                      return true;
                    } else {
                      setIsValidDiscount(false);
                      return 'Not a valid discount price';
                    }
                  },
                }}
              ></Textbox>
              {isLoading ? (
                <PropagateLoader
                  className='loadingSpinner'
                  loading={isLoading}
                  color='#00bc64'
                  size={15}
                  speedMultiplier={2}
                  aria-label='Loading Spinner'
                  data-testid='loader'
                />
              ) : (
                <button type='submit' className='saveToDatabase'>
                  Save
                </button>
              )}
              {/* Display the error message */}
              {error && <p className='errorMessage'>{error}</p>}
            </form>
            {isAddingBranch && (
              <>
                <p>
                  <b>Current Branches:</b> {showBranches().join(', ')}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTreeOrBranchForm;
