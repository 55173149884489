import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, getIdToken } from 'firebase/auth';
import { auth } from '../../services/Auth';
import { createJiraBugIssue } from '../../services/APIFetchers';
import PropagateLoader from 'react-spinners/PropagateLoader';
import '../memberTable/MemberTable.css';

const BugForm = ({ isVisible, setIsVisible }) => {
  const [firebaseToken, setFirebaseToken] = useState(null);
  const [isContactSuccess, setIsContactSuccess] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) setFirebaseToken(await getIdToken(user, true));
    });
  }, []);

  // BUG HANDLER
  const handleSubmit = async (form) => {
    form.preventDefault();
    setIsLoading(true);
    const target = form.target;
    let bugSummary = target.bugSummary.value;
    let bugDescription = target.bugDescription.value;

    try {
      const response = await createJiraBugIssue(
        bugSummary,
        bugDescription,
        firebaseToken
      ).then((resp) => {
        if (resp.success) setIsContactSuccess(true);
        else setIsContactSuccess(false);
      });
      setIsLoading(false);

      //TODO  Naviagte to a success page with the response the user included
    } catch (error) {
      console.error('Error creating issue:', error);
    }
  };

  return (
    <>
      {isVisible && (
        <>
          <div
            className='backgroundBlur'
            onClick={() => setIsVisible(false)}
          ></div>
          <div className='bugFormContainer'>
            <div className='bugFormHolder'>
              <button className='closeBtn' onClick={() => setIsVisible(false)}>
                X
              </button>
              <form className='bug-form' onSubmit={(e) => handleSubmit(e)}>
                <h2>Report an issue</h2>
                <p>What issue are you having? Please let us know below.</p>
                <label>Issue title:</label>
                <input
                  name='bugSummary'
                  type='text'
                  placeholder='E.g. Add member button'
                  required
                  className='inputs'
                />
                <label>Issue Description:</label>
                <textarea
                  name='bugDescription'
                  placeholder="E.g. When clicking the button it doesn't work"
                  required
                  className='inputs'
                />
                {isLoading ? (
                  <PropagateLoader
                    className='loadingSpinner'
                    loading={isLoading}
                    color='#00bc64'
                    size={15}
                    speedMultiplier={2}
                    aria-label='Loading Spinner'
                    data-testid='loader'
                  />
                ) : (
                  <button className='submit-bug' type='submit'>
                    Submit
                  </button>
                )}
                {isContactSuccess == true && (
                  <p>Successfully submitted request!</p>
                )}
                {isContactSuccess == false && (
                  <p>Failed to send! Please try again.</p>
                )}

                <div className='formDivider'></div>
                <p>
                  No issues? Want to chat? Contact{' '}
                  <a href='mailto:luke@sharetree.io'>luke@sharetree.io</a>
                </p>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BugForm;
