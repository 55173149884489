import React, { useState } from 'react';
import '../styles/pages/Branches.css';
import TopBar from '../components/topBar/topBar.js';
import MemberTable from '../components/memberTable/MemberTable';
import { AddTree, submitAddTree } from '../components/addTree/AddTree';
import AddTreeOrBranchForm from '../components/addTree/AddTreeOrBranchForm';

const Branches = () => {
  const [isAddTreeFormVisible, setIsAddTreeFormVisible] = useState(false);
  const [isAddBranchFormVisible, setIsAddBranchFormVisible] = useState(false);

  return (
    <>
      {isAddTreeFormVisible && (
        <AddTreeOrBranchForm
          setToggle={setIsAddTreeFormVisible}
          submitForm={submitAddTree}
        />
      )}
      {isAddBranchFormVisible && (
        <AddTreeOrBranchForm
          setToggle={setIsAddBranchFormVisible}
          submitForm={submitAddTree}
          branchFor={isAddBranchFormVisible}
        />
      )}
      <div className='branchContainer'>
        <div className='componentContainers'>
          <TopBar />
          <div className='memberDetailContainer'>
            <div className='membersTableContainer'>
              <MemberTable
                setIsAddBranchFormVisible={setIsAddBranchFormVisible}
              />
            </div>

            <div className='memberAddMemberDetails'>
              <div className='addTreeContainer'>
                <AddTree setIsAddTreeFormVisible={setIsAddTreeFormVisible} />
              </div>
              <div className='showCurrentMember'>
                <h2>Feature Coming Soon</h2>
                <h4>View individual members information</h4>
                <p>
                  Have the ability to see the information on an individual
                  Member e.g. member details, referred members, money they've
                  saved and money they've brought to the business.{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Branches;
