import React, { Component } from 'react';
import Navbar from '../components/common/Header';

import '../styles/pages/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar />
      <h1 className='privacyHead'>Global Privacy Policy</h1>
      <p>Last Updated: November 24 2023 </p>

      <p className='privacyStart'>
        Welcome to ShareTree! ShareTree, hereafter referred to as "we," "us," or "our," is committed to protecting the privacy and security of your
        personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our
        referral-based software system for gyms.
      </p>

      <ul className='privacyList'>
        <h3>
          <span className='privacyNum'>1.</span> Information We Collect:
        </h3>
        <li className='privacyListItems'>
          <h4>
            <span className='privacyNum2'>a.</span> Account Information:{' '}
          </h4>
          When you sign up for ShareTree, we collect personal information such as your name, email address, and password.
        </li>
        <li className='privacyListItems'>
          <h4>
            {' '}
            <span className='privacyNum2'>b.</span> Referral Data:
          </h4>{' '}
          To facilitate the referral-based system, we collect data about gym referrals, including the names and contact information of referred
          individuals.{' '}
        </li>

        <li className='privacyListItems'>
          <h4>
            {' '}
            <span className='privacyNum2'>c.</span> Usage Information:{' '}
          </h4>
          We may collect information about your use of ShareTree, including the pages you visit, the features you use, and the actions you take.{' '}
        </li>

        <li className='privacyListItems'>
          <h4>
            <span className='privacyNum2'>d.</span> Device Information:
          </h4>
          Device Information: We may collect information about the device you use to access ShareTree, including device type, operating system, and
          browser type.
        </li>

        <li className='privacyListItems'>
          <h4>
            <span className='privacyNum2'>e.</span> Location Information:
          </h4>
          With your explicit consent, we may collect location information to provide location-based services.
        </li>

        <h3>
          <span className='privacyNum'>2.</span> How We Use Your Information:
        </h3>
        <li className='privacyListItems'>
          <h4>
            <span className='privacyNum2'>a.</span> Provide and Improve Services:
          </h4>
          We use your information to provide, maintain, and improve ShareTree's services, including personalized features.
        </li>

        <li className='privacyListItems'>
          <h4>
            <span className='privacyNum2'>b.</span> Communications:
          </h4>
          We may use your contact information to send you service-related communications, updates, and promotional messages.{' '}
        </li>
        <li className='privacyListItems'>
          <h4>
            <span className='privacyNum2'>c.</span> Referral Program:
          </h4>
          Your information is used to manage and track gym referrals, ensuring accurate attribution of referrals and rewards.{' '}
        </li>
        <li className='privacyListItems'>
          <h4>
            <span className='privacyNum2'>d.</span> Analytics:
          </h4>
          We use analytics tools to understand how users interact with ShareTree, helping us improve the user experience.{' '}
        </li>
        <li className='privacyListItems'>
          <h4>
            <span className='privacyNum2'>e.</span> Communications:
          </h4>
          We may use your contact information to send you service-related communications, updates, and promotional messages.{' '}
        </li>

        <h3>
          <span className='privacyNum'>3.</span> Sharing Your Information:
        </h3>

        <li className='privacyListItems'>
          <h4>
            <span className='privacyNum2'>a.</span> With Your Consent:
          </h4>
          We may share your information with third parties if you give us explicit consent to do so.{' '}
        </li>
        <li className='privacyListItems'>
          <h4>
            <span className='privacyNum2'>b.</span> Service Providers:
          </h4>
          We may engage third-party service providers to assist with various aspects of ShareTree's operation, and they may have access to your
          information for this purpose.{' '}
        </li>
        <li className='privacyListItems'>
          <h4>
            <span className='privacyNum2'>c.</span> Legal Compliance:
          </h4>
          We may disclose your information if required by law or in response to a legal request.{' '}
        </li>
        <li className='privacyListItems'>
          <h4>
            <span className='privacyNum2'>d.</span> Communications:
          </h4>
          We may use your contact information to send you service-related communications, updates, and promotional messages.{' '}
        </li>

        <h3>
          <span className='privacyNum'>4.</span> Your Choices:
        </h3>

        <li className='privacyListItems'>
          <h4>
            <span className='privacyNum2'>a.</span> Account Information:
          </h4>
          You can update or delete your account information by accessing your account settings.{' '}
        </li>
        <li className='privacyListItems'>
          <h4>
            <span className='privacyNum2'>b.</span> Communication Preferences:
          </h4>
          You can manage your communication preferences by adjusting your account settings or unsubscribing from promotional emails.{' '}
        </li>
        <li className='privacyListItems'>
          <h4>
            <span className='privacyNum2'>c.</span> Location Information:
          </h4>
          You can control whether we collect location information through your device settings.{' '}
        </li>
        <li className='privacyListItems'>
          <h4>
            <span className='privacyNum2'>d.</span> Communications:
          </h4>
          We may use your contact information to send you service-related communications, updates, and promotional messages.{' '}
        </li>

        <h3>
          <span className='privacyNum'>5.</span> Security:{' '}
        </h3>
        <li className='privacyListItems'>
          We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, and destruction. However, no
          method of transmission over the internet or electronic storage is entirely secure.
        </li>

        <h3>
          <span className='privacyNum'>6.</span> Children's Privacy:
        </h3>
        <li className='privacyListItems'>
          ShareTree is not intended for individuals under the age of 13. We do not knowingly collect or solicit personal information from children. If
          we become aware that we have collected personal information from a child under 13, we will take steps to delete the information promptly.
        </li>

        <h3>
          <span className='privacyNum'>7.</span> Changes to this Privacy Policy:
        </h3>
        <li className='privacyListItems'>
          We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify
          you of any changes by posting the revised policy on our website or through other appropriate channels.
        </li>

        <h3>
          <span className='privacyNum'>8.</span> Contact Us:
        </h3>
        <li className='privacyListItems'>
          Contact Us: If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at [luke@sharetree.com]. By
          using ShareTree, you agree to the terms outlined in this Privacy Policy.
        </li>
      </ul>
      <h5>Thank you for trusting ShareTree with your information!</h5>
    </div>
  );
};

export default PrivacyPolicy;
