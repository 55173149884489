import React, { Component } from "react";
import "../styles/pages/Pricing.css";
import Footer from "../components/common/Footer";
import Navbar from "../components/common/Header.js";
import { Link } from "react-router-dom";

function Pricing() {
  return (
    <div>
      <Navbar />
      <div className="mainPricing">
        <div className="pricingContent">
          <div className="pricingContext">
            <h1>
              Simple & <br /> Transparent Pricing
            </h1>
            <p>
              Experience the ease and clarity of our straightforward
              subscription pricing, no hidden fees or surprises.
            </p>
          </div>
          <div className="pricingContainer">
            <div className="insideContainer">
              <div className="pricing">
                <span className="dollarsign">$</span>
                <h2>99</h2>
                <span className="permonth">/per month</span>
              </div>
              <div className="paidFeatures">
                <ul className="featuresWithPrice">
                  <h3>
                    Free until you get your first 2 members through sharetree
                  </h3>
                  <li>
                    <svg id="featureIcon1"></svg>
                    Personalised dashboard
                  </li>
                  <li>
                    <svg id="featureIcon1"></svg>Member trees and branches
                  </li>
                  <li>
                    <svg id="featureIcon1"></svg>CRM integration
                  </li>
                  <li>
                    <svg id="featureIcon1"></svg>24/7 support
                  </li>
                </ul>
              </div>
              <Link to="/signup" className="pricingBtn">
                <button className="pricingBtn1">Try for free</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Pricing;
