// This file will have ALL of the API requests our app needs to make
const PRODURL = process.env.PROD_URL;
export const getMindBodyAuth = async (username, password, siteId, firebaseToken) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      firebaseToken: firebaseToken,
      username: username,
      password: password,
      siteId: siteId,
    }),
  };

  return await makeRequest('/mindbody/auth', requestOptions);
};

// NEED TO CONVERT THIS TO WORK FOR GETTING REFERAL LINK
// export const getMemberReferralLink = async (firebaseToken) => {
//   return await makeRequest('', {
//     method: 'GET',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify({
//       firebaseToken: firebaseToken,
//     }),
//   });
// };

export const getMindbodyMembers = async (firebaseToken) => {
  return await makeRequest('/mindbody/importClients', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      firebaseToken: firebaseToken,
    }),
  });
};

export const setMindBodyDiscountsBasic = async (firebaseToken, discountOnReferral, discountForReferee) => {
  return await makeRequest('/mindbody/saveDiscounts', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      firebaseToken: firebaseToken,
      discountOnReferral: discountOnReferral,
      discountForReferee: discountForReferee,
    }),
  });
};

export const createJiraBugIssue = async (summary, description, firebaseToken) => {
  return await makeRequest('/jira/bugfix', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      firebaseToken: firebaseToken,
      bug_summary: summary,
      bug_description: description,
    }),
  });
};

export const removeMindbodyIntegration = async (firebaseToken) => {
  return await makeRequest('/mindbody/removeIntegration', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      firebaseToken: firebaseToken,
    }),
  });
};

export const addReferralLink = async (firebaseToken, memberId) => {
  return await makeRequest('/api/generateReferralLink', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      firebaseToken: firebaseToken,
      memberId: memberId,
    }),
  });
};

export const syncWithMindbody = async (firebaseToken) => {
  return await makeRequest('/mindbody/sync', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      firebaseToken: firebaseToken,
    }),
  });
};

export const addProspectiveMember = async (name, email, id) => {
  return await makeRequest('/api/referral', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      memberName: name,
      email: email,
      referralLink: id,
    }),
  });
};

export const removeReferralLink = async (firebaseToken, referralId) => {
  return await makeRequest('/api/removeReferral', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      firebaseToken: firebaseToken,
      referralLink: referralId,
    }),
  });
};

export const makeRequest = async (path, requestOptions) => {
  try {
    const resp = await fetch(`${path}`, requestOptions);
    return await resp.json();
  } catch (e) {
    console.log(e);
    return e;
  }
};
