import '../styles/pages/Home.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from 'react-router-dom';
import React, { Component } from 'react';
import Footer from '../components/common/Footer';
import Information from './Information';
import Navbar from '../components/common/Header';

function Home() {
  return (
    <div>
      <Navbar />
      <div className='main-container'>
        <div className='home-container'>
          <div className='heading'>
            <h1>
              Building Loyal Communities <br />
              In Your Business
            </h1>
            <h5>
              Use your members to grow and retain customers using Sharetree’s
              dynamic referral software for memberships.
            </h5>
            <Link to={'/contact'}>
              <button id='cta-signup'>Try Sharetree for free</button>
            </Link>
          </div>
          <img
            className='img-ipad'
            src={require('../assets/images/sharetree-images/SharetreeMockup.png')}
          />
        </div>
        <div id='greenBackdrop'></div>
      </div>
      <div className='clientValidation'>
        <h2>6+ Businesses are ready to launch 🚀</h2>
        <div className='customer-logo-container'>
          <div className='logos'></div>
        </div>
      </div>
      <div className='sellingBg'>
        <div className='sellingPointsContainer'>
          <div className='sellingPoint1'>
            <div className='content1Container'>
              <div className='sell1Content'>
                <h1>The most cost-effective way to gain and retain members.</h1>
                <h6>Marketing that guarantees a return on investment (ROI).</h6>
                <p>
                  We’re recreating the way you market your business to grow and
                  retain customers by incentivising the people that love and use
                  your products and services most… Your customers.
                </p>
              </div>
              <div className='imageContainer1'>
                <img
                  className='icon2'
                  src={require('../assets/images/sharetree-images/Sharetree Custom Icon 2.png')}
                ></img>
              </div>
            </div>
          </div>
          <div className='sellingPoint2'>
            <div className='content1Container'>
              <div className='sell1Content'>
                <h1>Plant the seed, grow your trees.</h1>
                <h6>
                  Create lifelong, loyal customers and grow your business with a
                  new membership model.
                </h6>
                <p>
                  Use Sharetree’s referral marketing software to grow and retain
                  members with a stronger membership that automatically adjusts
                  member pricing based on effort a member puts in to bring in
                  and keep new referred friends as members, building out
                  communities with in your business.
                </p>
              </div>
              <div className='imageContainer1'>
                <img
                  className='icon2'
                  src={require('../assets/images/sharetree-images/Sharetree Custom Icons 1.png')}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='integrationPartnership'>
        <div className='integrationContainer'>
          <div className='iPinfo'>
            <h1>
              Partnerships & integrations across platforms powering your
              customers.
            </h1>
            <p>
              Sharetree has strong relationships and works closely with
              platforms used to monitor customers so we can simplify the process
              of tracking and automating members pricing and referrals to make
              life easier for you.
            </p>
            <div className='statistics'>
              {/* <div className="statSignUp">
                <h3>2M+</h3>
                <p>New Signups via Sharetree</p>
              </div> */}
              <div className='statCRM'>
                <h3>1</h3>
                <p>Supported CRM</p>
              </div>
            </div>
          </div>
          <div className='logoContainers'>
            <div className='mindbodyContainer'>
              <img
                className='mindBody'
                src={require('../assets/images/sharetree-images/Mindbody.png')}
              />
            </div>
          </div>
        </div>
      </div>

      {/* needs to be a seperate module with some fiunctionality. */}
      <div className='testimonials'>
        <div className='tesimonialContainer'>
          <div className='tesimonialContext'></div>
          <div className=''></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
