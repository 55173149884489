import '../styles/pages/Signup.css';
import React, { Component, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/common/Footer';
import { auth, database, userRef, writeData } from '../services/Auth';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import PropagateLoader from 'react-spinners/PropagateLoader';

function Signup() {
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [password, setpassword] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState({});
  const navigate = useNavigate();

  //   This function adds a new user on sign up
  const createUserClick = (e) => {
    e.preventDefault();
    setLoading(true);
    // Set loader to true
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredentials) => {
        // const user = userCredentials.user;
        const user = auth.currentUser;
        console.log(auth);
        if (user) {
          const uid = user.uid;
          //   set loader to false
          setLoading(false);

          writeData('users/' + uid, {
            username: fullName,
            email: email,
            member: members,
          });
          // redirects to Dashboard
          navigate('/dashboard');
          console.log(`User: ${fullName}, ${uid} is signed in.`);
        } else {
          console.log('I dont know how to fix');
        }
      })
      .catch((error) => {
        console.log(error);
        setIsActive(true);
        setTimeout(() => {
          setIsActive(false);
          setLoading(false);
        }, 3000);
      });
  };

  return (
    <div className='login-container'>
      <div className='coloredDivSignup'>
        <div className='coloredContent'>
          <Link to={'/'}>
            <svg id='SharetreeLogoWhite'></svg>
          </Link>
          <div className='coloredContentContext'>
            <h1>Begin your journey with Sharetree</h1>
            <p>
              Discover how our powerful referral software can help you grow and
              retain memberships.
            </p>
          </div>
        </div>
      </div>
      <div className='loginSideContainer'>
        <div className='dashboardLogin'>
          <h3> Create your FREE account</h3>
          <div className='login-user'>
            <form onSubmit={createUserClick} className='sign-up-form'>
              <label>
                First Name:
                <input
                  type='text'
                  placeholder='First name'
                  onChange={(e) => setFullName(e.target.value)}
                  required
                  className='inputs'
                ></input>
              </label>
              <label>
                Last Name:
                <input
                  type='text'
                  placeholder='Last name'
                  className='inputs'
                ></input>
              </label>
              <label>
                Email:
                <input
                  type='email'
                  placeholder='Email'
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className='inputs'
                ></input>
              </label>
              <label>
                Password:
                <input
                  type='Password'
                  placeholder='Password (must be aleast 8 characters)'
                  onChange={(e) => setpassword(e.target.value)}
                  required
                  className='inputs'
                ></input>
              </label>
              <p
                className='warning-error'
                style={{
                  display: isActive ? 'block' : 'none',
                }}
              >
                Password should be at least 6 characters.
              </p>
              <button className='submit-account' type='submit'>
                Create Account
              </button>{' '}
            </form>
            {/** by clicking this button, it will create a new user with the email and password values. */}
            <p className='account-already'>
              Already have an account? <a href='/Login'>Log in</a>
            </p>
          </div>
          <div className='loaderBar'>
            <PropagateLoader
              loading={loading}
              color='#00bc64'
              size={15}
              speedMultiplier={2}
              aria-label='Loading Spinner'
              data-testid='loader'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
