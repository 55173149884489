import React, { Component } from 'react';
import Footer from '../components/common/Footer';
import Navbar from '../components/common/Header';
import '../styles/pages/About.css';

function About() {
  return (
    <div>
      <Navbar />
      <div className='aboutMain'>
        <div className='aboutContainer'>
          <div className='aboutContextContainer'>
            <h1>Our Mission</h1>
            <p>
              Every customer has their own network of friends, family and
              acquaintances.
              <br />
              <br /> By reducing customer prices to keep retention and grow your
              business with newly referred customers it’s a win, win for
              everyone. <br /> <br /> Our mission is to create loyal communities
              that build your business and help your customers.
            </p>
          </div>
          <img
            className='aboutImageContainer'
            src={require('../assets/images/sharetree-images/Mission Image.png')}
          ></img>
        </div>
      </div>
      <div className='foundingStory'>
        <div className='foundingStoryContainer'>
          <div className='imageContainer'></div>
          <div className='foundingContentContainer'>
            <h1>The Founding Story</h1>
            <p>
              It started with a vision Luke Mancuso saw in 2023 when business at
              the gym he worked at was declining due to customers cancelling the
              service because of financial reasons. He wanted to help solve the
              problem of businesses struggling to grow and retain customers and
              customers leaving something they enjoyed because of money. This is
              where the idea of a completely new marketing and financial model
              was designed in Sharetree. <br />
              <br />
              After developing the Sharetree prototype and having great
              responses from customers he reached out to a friend, Nick Georgiou
              who brought in friend, Joseph Hanna to help with building out the
              project and became the Sharetree team and partners.
              <br />
              <br /> Today, the Sharetree software is creating a completely new
              financial and marketing model that is helping customers save money
              by using their networks to build loyal communities and grow
              businesses they support.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
