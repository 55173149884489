// This will be the 404 error page
import Navbar from "../../components/common/Header";
import { useNavigate } from "react-router-dom";
import "../../styles/pages/error404.css";

const Error404Handler = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <Navbar />
      <div className="error-404Container">
        <div className="context404Containers">
          <h1 className="error-404">404</h1>
          <div className="error-404Text">
            <div className="giphyContainer">
              <div className="blocker"></div>
              <iframe
                src="https://giphy.com/embed/3ktLJJs5fYT6NP005b"
                width="480"
                height="270"
                frameBorder="0"
                className="giphy-embed"
                allowFullScreen
              ></iframe>
            </div>
            <h3 className="errorHeading">OOPS.. PAGE NOT FOUND!</h3>
            <button className="button404" onClick={goBack}>
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404Handler;
