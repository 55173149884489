import React, { Component } from 'react';
import { useLocation } from 'react-router-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from 'react-router-dom';
import '../../styles/components/common/Header.css';

const Navbar = () => {
  return (
    <nav className='nav'>
      <div className='nav-container'>
        <div className='item-container'>
          <Link to='/' className='logo-link'>
            <svg id='Sharetree_Logo'></svg>
          </Link>
          <ul className='nav-items'>
            <li>
              <Link to='/pricing' className='nav-links'>
                Pricing
              </Link>
            </li>
            <li>
              <Link to='/about' className='nav-links'>
                About
              </Link>
            </li>
            <li>
              <Link to='/contact' className='nav-links'>
                Contact
              </Link>
            </li>
          </ul>
        </div>

        <div className='nav-buttons'>
          <Link to='/login'>
            <button type='button' className='login-btn'>
              Log In
            </button>
          </Link>
          {/* <Link to="/signup">
            <button type="button" className="signUpNav">
              Join today
            </button>
          </Link> */}
        </div>
      </div>
    </nav>
    // </div>
  );
};

export default Navbar;
