import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './AddTree.css';
import {
  auth,
  writeData,
  updateData,
  getData,
  removeData,
} from '../../services/Auth';
import { addReferralLink } from '../../services/APIFetchers';
import { onAuthStateChanged, getIdToken } from 'firebase/auth';
import { GenerateEmailId } from '../../services/UsefulFunctions';

const writeMemberToDatabase = async (member) => {
  const currentUser = auth.currentUser.uid;
  await writeData(
    `users/${currentUser}/members/${GenerateEmailId(member.memberEmail)}`,
    member,
    'Error adding member to database: '
  ).then(() => {
    console.log(`updated ${currentUser}...`);
  });
};

const writeBranchToDatabase = async (referringMember, branchMember) => {
  const currentUser = auth.currentUser.uid;
  const baseDBPath = `users/${currentUser}/members/${GenerateEmailId(
    referringMember.memberEmail
  )}`;

  await writeData(
    `${baseDBPath}/branchDetails/${GenerateEmailId(branchMember.memberEmail)}`,
    {
      branchEmail: branchMember.memberEmail,
      branchName: branchMember.memberName,
    },
    'Error adding branch to member: '
  )
    .then(async () => {
      updateData(`${baseDBPath}`, {
        branches: Object.keys(await getData(`${baseDBPath}/branchDetails`))
          .length,
      });
    })
    .then(() => console.log('Finished adding branch'));
};

const submitAddTree = async (
  member,
  form,
  isAddingBranchToo = false,
  isProspectiveToo = false
) => {
  onAuthStateChanged(auth, async (user) => {
    const firebaseToken = await getIdToken(user, true);
    let referralDetails = null;
    await writeMemberToDatabase(member).then(
      async () =>
        (referralDetails = await addReferralLink(
          firebaseToken,
          GenerateEmailId(member.memberEmail)
        ))
    );

    // TODO: Email to referring member when branch is added
    if (isAddingBranchToo)
      await writeBranchToDatabase(isAddingBranchToo, member);
    if (isProspectiveToo)
      await removeData(
        `prospectiveMembers/${auth.currentUser.uid}/${GenerateEmailId(
          isProspectiveToo.prospectiveMember.memberEmail
        )}`
      );
    window.location.reload();
  });
};

const AddTree = ({ setIsAddTreeFormVisible }) => {
  const element = <FontAwesomeIcon className='text-btn-icon' icon={faPlus} />;

  return (
    <div className='add-new-container'>
      <div className='add-btn-text'>
        <h2 className='btn-heading'>Add New Tree</h2>
      </div>
      <button
        className='add-new-member-btn'
        onClick={(e) => {
          setIsAddTreeFormVisible(true);
        }}
      >
        {element}
      </button>
    </div>
  );
};

export { AddTree, submitAddTree };
