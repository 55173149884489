export const GenerateEmailId = (email) => {
  return email.replaceAll('.', '').toLowerCase();
};

// Build on this as we add more integrations over time
export const isMemberManagedByIntegration = (member) => {
  if (member.mindbodyId != undefined) return 'Mindbody';
  return undefined;
};

export const getLocalDateFromString = (date) => {
  const parsed_date = Date.parse(date);
  let new_date = new Date();
  new_date.setTime(parsed_date);
  return new_date.toLocaleString();
};
