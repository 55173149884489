import './App.css';
import React, { useEffect, useState } from 'react';
import { auth } from './services/Auth';
import { onAuthStateChanged } from 'firebase/auth';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './pages/About';
import Pricing from './pages/Pricing';
import Dashboard from './pages/Dashboard';
import ProspectiveMembers from './pages/dashboard/ProspectiveMembers';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Home from './pages/Home';
import Contact from './pages/Contact';
import MindbodySetup from './pages/mindbody/MindbodySetup';
import ReferralLink from './pages/Referral';
import ReferralForm from './pages/ReferralForm';
import Branches from './pages/Branches';
import Error404Handler from './pages/errors/404Handler';
import LandingPage from './pages/landingPage/landingPage';
import PrivacyPolicy from './pages/PrivacyPolicy';

const App = () => {
  const [user, setUser] = useState('');

  return (
    <Router>
      <div className='App'>
        <Routes>
          <Route path='/contact' Component={Contact} />
          <Route path='/pricing' Component={Pricing} />
          <Route path='/about' Component={About} />
          <Route path='/dashboard' Component={Dashboard} />
          <Route path='/dashboard/prospective-members' Component={ProspectiveMembers} />
          <Route path='/dashboard/branches' Component={Branches} />
          <Route path='/login' Component={Login} />
          <Route path='/' Component={Home} />
          <Route path='/referral-links' Component={ReferralLink} />
          <Route path='/referral/:business_id' Component={ReferralForm} />
          <Route path='/landingPage' Component={LandingPage} />
          {/* <Route path='/signup' Component={Signup} /> */}
          <Route path='*' Component={Error404Handler} />
          <Route path='/mindbody-setup' Component={MindbodySetup} />
          <Route path='/privacyPolicy' Component={PrivacyPolicy} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
