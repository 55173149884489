import '../../styles/pages/Branches.css';
import React, { useState } from 'react';
import ProspectiveTable from '../../components/prospectiveTable/ProspectiveTable';
import SideBar from '../../components/sideBar/SideBar';
import BugForm from '../../components/sideBar/BugForm';
import TopBar from '../../components/topBar/topBar';
import AddTreeOrBranchForm from '../../components/addTree/AddTreeOrBranchForm';
import { submitAddTree } from '../../components/addTree/AddTree';

const ProspectiveMembers = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isApproveFormVisible, setIsApproveFormVisible] = useState(false);

  return (
    <div className='dashboardContainer'>
      <SideBar setIsVisible={setIsVisible} />

      <div className='componentContainer'>
        <BugForm isVisible={isVisible} setIsVisible={setIsVisible} />
        {isApproveFormVisible && (
          <AddTreeOrBranchForm setToggle={setIsApproveFormVisible} submitForm={submitAddTree} branchFor={true} prospective={isApproveFormVisible} />
        )}
        <div className='branchContainer'>
          <div className='componentContainers'>
            <TopBar />
            <div className='memberDetailContainer'>
              <ProspectiveTable setIsApproveFormVisible={setIsApproveFormVisible} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProspectiveMembers;
