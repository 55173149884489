import React, { Component, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth, getData } from '../../services/Auth';
import '../topBar/topBar.css';
import { onAuthStateChanged, getAuth, signOut } from 'firebase/auth';

const TopBar = () => {
  const navigate = useNavigate();
  const [isdropped, setIsDropped] = useState(false);
  const [name, setName] = useState();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        getData(`users/${user.uid}/username`).then((resp) => setName(resp));
      }
    });
  }, []);

  const searchErr = () => {
    alert(
      'Search Button currently not active in MVP: Contact luke@sharetree.io'
    );
  };

  const accountDropHandler = () => {
    console.log('drop down appears');
  };

  const accSettingsHandler = () => {
    // alert not active in MVP- Contact luke@sharetree.io
    alert('Settings currently not active in MVP: Contact luke@sharetree.io');
    console.log('account settings ');
  };

  const logOutHandler = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate('/login');
        console.log('Signed out successfully!');
      })
      .catch((error) => {
        console.log(`Didnt sign out ${error}`);
      });
    console.log('logout ');
  };

  return (
    <div className='topBarContainer'>
      <div className='searchBarContainer'>
        <div id='searchBar'>
          <input
            id='searchTerm'
            type={'text'}
            placeholder='Search'
            onClick={searchErr}
          ></input>
          <button className='searchBtn'>
            <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 26.994 26.994'>
              <g id='Icon_feather-search' data-name='Icon feather-search' transform='translate(-3 -3)'>
                <path
                  id='Path_5'
                  data-name='Path 5'
                  d='M25.276,14.888A10.388,10.388,0,1,1,14.888,4.5,10.388,10.388,0,0,1,25.276,14.888Z'
                  fill='none'
                  stroke='#135658'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
                <path
                  id='Path_6'
                  data-name='Path 6'
                  d='M30.623,30.623l-5.648-5.648'
                  transform='translate(-2.75 -2.75)'
                  fill='none'
                  stroke='#135658'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='3'
                />
              </g>
            </svg>
          </button>
        </div>
      </div>

      <div className='accountDetailsContainer'>
        <div className='accountInfo'>
          <div className='accountBtnContainer'>
            <button id='accountBtn' onClick={() => setIsDropped((prevCheck) => !prevCheck)}>
              <svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 42 42'>
                <g id='Profile' transform='translate(-1637 -69)'>
                  <circle id='Ellipse_13' data-name='Ellipse 13' cx='21' cy='21' r='21' transform='translate(1637 69)' fill='#00ae70' />
                  <path
                    id='Icon_material-person-outline'
                    data-name='Icon material-person-outline'
                    d='M14.121,7.929a2.132,2.132,0,1,1-2.132,2.132,2.132,2.132,0,0,1,2.132-2.132m0,9.136c3.015,0,6.192,1.482,6.192,2.132v1.117H7.929V19.2c0-.65,3.177-2.132,6.192-2.132M14.121,6a4.061,4.061,0,1,0,4.061,4.061A4.059,4.059,0,0,0,14.121,6Zm0,9.136C11.411,15.136,6,16.5,6,19.2v3.045H22.242V19.2C22.242,16.5,16.832,15.136,14.121,15.136Z'
                    transform='translate(1643.879 74.879)'
                    fill='#fff'
                  />
                </g>
              </svg>{' '}
              {name}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='11'
                height='6'
                // width="13.468"
                // height="7.701"
                viewBox='0 0 13.468 7.701'
              >
                <path
                  id='Icon_ionic-ios-arrow-down'
                  data-name='Icon ionic-ios-arrow-down'
                  d='M12.924,16.626l5.093-5.1a.958.958,0,0,1,1.359,0,.971.971,0,0,1,0,1.363l-5.77,5.774a.961.961,0,0,1-1.327.028l-5.81-5.8a.963.963,0,0,1,1.359-1.363Z'
                  transform='translate(-6.188 -11.246)'
                  fill='#afafaf'
                />
              </svg>
            </button>
          </div>
        </div>
        {isdropped && (
          <div className='dropDown'>
            <ul>
              <li onClick={accSettingsHandler}>Account Settings</li>
              <li onClick={logOutHandler}>Log out</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopBar;
